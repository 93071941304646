const defaultState = {
	currentPeerId: '',
	remoteVideoWidth: 0,
	remoteVideoHeight: 0,
	videoPacketsLost: 0,
	audioPacketsLost: 0,
	framesReceived: 0,
	currentRoundTripTime: 0,
	availableOutgoingBitrate: 0,
	remoteIpAddress: '',
	localIpAddress: '',
	videoCodec: '',
	audioCodec: '',
	timestamp: 0,
	remoteFrameRate: 0,
	videoDelay: 0,
	audioDelay: 0,
	remoteNetworkType: null,
	remoteOutgoingBitrate: 0
};

function getRTCIceCandidatePairStats(stats) {
	if (!stats) {
		return [0,0];
	}
	const RTCICECandidatePairKey = Object.keys(stats).filter(key => {
		if (stats[key].type === "candidate-pair") {
			if (stats[key].nominated) {
				return true;
			}
		}
		return false;
	})[0] || '';
	return (RTCICECandidatePairKey ? stats[RTCICECandidatePairKey] : {});

}

function getIncomingVideoStats(stats) {
	if (!stats) {
		return [0,0];
	}
	const receiveVideoStream = Object.values(stats).filter(stat => {
		if (stat?.type === 'inbound-rtp' && stat?.kind === 'video') {
			return true;
		}
		return false;
	});
	return (receiveVideoStream[0] ? receiveVideoStream[0] : {});
}

function getIncomingVideoStreamStats(stats) {
	if (!stats) {
		return [0,0];
	}
	const receiveVideoStream = Object.values(stats).filter(stat => {
		if (stat?.type === 'inbound-rtp' && stat?.kind === 'video') {
			return true;
		}
		return false;
	});
	return (receiveVideoStream[0] ? receiveVideoStream[0] : {});
}

function getIncomingAudioStreamStats(stats) {
	if (!stats) {
		return [0,0];
	}
	const receiveAudioStream = Object.values(stats).filter(stat => {
		if (stat?.type === 'inbound-rtp' && stat?.kind === 'audio') {
			return true;
		}
		return false;
	});
	return (receiveAudioStream[0] ? receiveAudioStream[0] : {});
}
function stats(state={...defaultState}, action) {
	switch (action.type) {
	case 'ON_REMOTE_STREAM': {
		if (!action || !action.payload) {
			return {...defaultState};
		}
		if (!action.payload.PeerId || state.currentPeerId !== action.payload.PeerId) {
			return {...defaultState, currentPeerId: action.payload.PeerId};
		}
		return {...state, currentPeerId: action.payload.PeerId};
	}
	case 'ON_WEBRTC_STATS': {
		if (!action || !action.payload) {
			return state;
		}
		const incomingVideoStats = getIncomingVideoStats(action.payload);
		const RTCIceCandidatePairStats = getRTCIceCandidatePairStats(action.payload);
		
		let remoteIpAddress = '';
		let localIpAddress = '';
		const remoteCandidateId = RTCIceCandidatePairStats.remoteCandidateId;
		const localCandidateId = RTCIceCandidatePairStats.localCandidateId;
		if (remoteCandidateId && action.payload[remoteCandidateId]) {
			remoteIpAddress = action.payload[remoteCandidateId].ip + ':' + action.payload[remoteCandidateId].port + 
					(action.payload[remoteCandidateId].candidateType === 'relay' ? ' (Relay)' : '');
		}
		if (localCandidateId && action.payload[localCandidateId]) {
			localIpAddress = action.payload[localCandidateId].ip + ':' + action.payload[localCandidateId].port +
					(action.payload[localCandidateId].candidateType === 'relay' ? ' (Relay)' : '');
		}

		const incomingVideoStreamStats = getIncomingVideoStreamStats(action.payload);
		const incomingAudioStreamStats = getIncomingAudioStreamStats(action.payload);

		return {
			...state,
			remoteVideoWidth: incomingVideoStats.frameWidth || 0,
			remoteVideoHeight: incomingVideoStats.frameHeight || 0,
			framesReceived: incomingVideoStats.framesReceived,
			videoPacketsLost: incomingVideoStreamStats.packetsLost,
			audioPacketsLost: incomingAudioStreamStats.packetsLost,
			availableOutgoingBitrate: RTCIceCandidatePairStats.availableOutgoingBitrate,
			currentRoundTripTime: RTCIceCandidatePairStats.currentRoundTripTime,
			remoteIpAddress,
			localIpAddress,
			timestamp: incomingVideoStats.timestamp,
			remoteFrameRate:
				((incomingVideoStats.framesReceived - state.framesReceived)/
				(incomingVideoStats.timestamp - state.timestamp)) * 1000 || 0,
			videoCodec: ((action.payload['ssrc_'+incomingVideoStreamStats.ssrc+'_recv'] || {}).googCodecName || 'Unknown'),
			audioCodec: ((action.payload['ssrc_'+incomingAudioStreamStats.ssrc+'_recv'] || {}).googCodecName || 'Unknown'),
			videoDelay: JSON.parse((action.payload['ssrc_'+incomingVideoStreamStats.ssrc+'_recv'] || {}).googCurrentDelayMs || 0),
			audioDelay: JSON.parse((action.payload['ssrc_'+incomingAudioStreamStats.ssrc+'_recv'] || {}).googCurrentDelayMs || 0),
		};
	}
	case 'ON_OBJECT_TO_PEER_FULFILLED': {
		if (action.payload.SenderId !== state.currentPeerId ||
			!action.payload.MessageObject || action.payload.MessageObject.ObjectType !== 'Stats') {
			return state;
		}
		const RTCIceCandidatePairStats = getRTCIceCandidatePairStats(action.payload.MessageObject);
		if (!Object.keys(RTCIceCandidatePairStats).length) {
			return state;
		}
		const localCandidate = action.payload.MessageObject[(RTCIceCandidatePairStats || {}).localCandidateId] || {};
		return {
			...state,
			remoteOutgoingBitrate: (RTCIceCandidatePairStats || {}).availableOutgoingBitrate,
			remoteNetworkType: (localCandidate || {}).networkType
		};
	}
	default:
		return state;
	}
}

export default stats;